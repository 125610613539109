import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Text, Flex } from "@skillup/design-system";
import { Status, DSModal, DSButton, StatusType } from "@skillup/ui";

import useCampaignFeedbackForm from "services/peopleReview/campaign/useCampaignFeedbackForm";
import { closeCampaign } from "services/peopleReview/useCampaignDetails/updateCampaignState";

import { Campaign } from "../../types";
import { StyledTitleContainer } from "./CloseCampaignModal.styled";
import { CampaignFeedbackForm } from "../CampaignFeedbackForm/CampaignFeedbackForm";

interface CloseCampaignModalProps {
  campaign: Campaign;
  onClose: () => void;
}

export function CloseCampaignModal({ campaign, onClose }: CloseCampaignModalProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isEdit, setIsEdit] = useState(false);

  const { isLoading: isCloseLoading, mutate: close } = useMutation(closeCampaign, {
    onSuccess: () => {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign", campaign.id]);
      onClose();
    },
  });

  const {
    handleChange,
    handleSubmit,
    isCommentSharedToManager,
    sharedToManagerActionIds,
    sharedToManagerCoreHRFieldsIds,
  } = useCampaignFeedbackForm({
    campaignID: campaign.id,
    onMutationSuccess: () => {
      setIsEdit(false);
    },
  });

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("peopleReview.campaign.closeCampaignModal.title", {
            defaultValue: "Clôturer la campagne",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        {!isEdit ? (
          <CampaignFeedbackSummary
            campaign={campaign}
            setIsEdit={setIsEdit}
            data={{
              isCommentSharedToManager,
              sharedToManagerActionIds,
              sharedToManagerCoreHRFieldsIds,
            }}
          />
        ) : (
          <CampaignFeedbackForm
            hasSubmitButton
            campaign={campaign}
            onChange={handleChange}
            onSubmit={handleSubmit}
            data={{
              isCommentSharedToManager,
              sharedToManagerActionIds,
              sharedToManagerCoreHRFieldsIds,
            }}
          />
        )}
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("peopleReview.campaign.closeCampaignModal.cancel", {
            defaultValue: "Annuler",
          })}
        />

        <DSModal.Footer.PrimaryButton
          tooltipDirection="bottom"
          disabled={isEdit || isCloseLoading}
          onClick={() => close(campaign.id)}
          label={t("peopleReview.campaign.closeCampaignModal.closeCampaign", {
            defaultValue: "Clôturer la campagne",
          })}
          tooltip={
            isEdit
              ? t("peopleReview.campaign.feedbackSettingsModal.closeCampaignTooltip", {
                  defaultValue: "Les modifications doivent être enregistrées avant de poursuivre",
                })
              : undefined
          }
        />
      </DSModal.Footer>
    </DSModal>
  );
}

function CampaignFeedbackSummary({
  campaign,
  data,
  setIsEdit,
}: {
  campaign: Campaign;
  setIsEdit: (isEdit: boolean) => void;
  data: {
    isCommentSharedToManager: boolean;
    sharedToManagerActionIds: string[];
    sharedToManagerCoreHRFieldsIds: string[];
  };
}) {
  const { t } = useTranslation();
  const { isCommentSharedToManager, sharedToManagerActionIds, sharedToManagerCoreHRFieldsIds } =
    data;

  const campaignFields = useMemo(() => {
    return campaign.fields.map((field) => {
      const isFieldShared = sharedToManagerCoreHRFieldsIds.includes(field.uuid);
      return {
        key: field.label,
        status: isFieldShared,
      };
    });
  }, [campaign.fields, sharedToManagerCoreHRFieldsIds]);

  const campaignActions = useMemo(() => {
    return campaign.actions.map((action) => {
      const isActionShared = sharedToManagerActionIds.includes(action.id);

      return {
        key: action.label,
        status: isActionShared,
      };
    });
  }, [campaign.actions, sharedToManagerActionIds]);

  return (
    <Flex gap="s" paddingBottom="s" flexDirection="column">
      <Text espaceFont="body1Regular" color="plainText-onLight-default">
        {t("peopleReview.campaign.closeCampaignModal.description", {
          defaultValue:
            "Voici les éléments qui seront partagés avec les managers une fois la campagne clôturée :",
        })}
      </Text>

      <Flex
        gap="m"
        position="relative"
        paddingVertical="xs"
        paddingHorizontal="s"
        flexDirection="column"
        backgroundColor="greyscale-lighter"
      >
        <Flex gap="m" flexDirection="column">
          <Flex gap="s" flexDirection="row">
            <StyledTitleContainer>
              <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                {t("peopleReview.campaign.closeCampaignModal.evalutationCriteria", {
                  defaultValue: "Critères d'évaluation",
                })}
              </Text>
            </StyledTitleContainer>

            <Flex flexDirection="column">
              {campaignFields.map((field) => (
                <Flex
                  gap="s"
                  key={field.key}
                  flexDirection="row"
                  alignItems="center"
                  paddingVertical="xxs"
                  justifyContent="space-between"
                >
                  <Status
                    type={field.status ? StatusType.SUCCESS : StatusType.DONE}
                    label={
                      field.status
                        ? t("peopleReview.campaign.closeCampaignModal.shared", {
                            defaultValue: "Partagé",
                          })
                        : t("peopleReview.campaign.closeCampaignModal.notShared", {
                            defaultValue: "Non partagé",
                          })
                    }
                  />

                  <Flex flex={1}>
                    <Text espaceFont="body1Regular" color="plainText-onLight-default">
                      {t(field.key)}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>

          <Flex gap="s" flexDirection="row">
            <StyledTitleContainer>
              <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                {t("peopleReview.campaign.closeCampaignModal.actions", {
                  defaultValue: "Actions et commentaires associés",
                })}
              </Text>
            </StyledTitleContainer>

            <Flex flexDirection="column">
              {campaignActions.map((action) => (
                <Flex
                  gap="s"
                  key={action.key}
                  flexDirection="row"
                  alignItems="center"
                  paddingVertical="xxs"
                  justifyContent="space-between"
                >
                  <Status
                    type={action.status ? StatusType.SUCCESS : StatusType.DONE}
                    label={
                      action.status
                        ? t("peopleReview.campaign.closeCampaignModal.shared", {
                            defaultValue: "Partagé",
                          })
                        : t("peopleReview.campaign.closeCampaignModal.notShared", {
                            defaultValue: "Non partagé",
                          })
                    }
                  />

                  <Flex flex={1}>
                    <Text espaceFont="body1Regular" color="plainText-onLight-default">
                      {t(action.key)}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>

          <Flex gap="s" flexDirection="row">
            <StyledTitleContainer>
              <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                {t("peopleReview.campaign.closeCampaignModal.other", {
                  defaultValue: "Autres",
                })}
              </Text>
            </StyledTitleContainer>

            <Flex flexDirection="column">
              <Flex
                gap="s"
                flexDirection="row"
                alignItems="center"
                paddingVertical="xxs"
                justifyContent="space-between"
              >
                <Status
                  type={isCommentSharedToManager ? StatusType.SUCCESS : StatusType.DONE}
                  label={
                    isCommentSharedToManager
                      ? t("peopleReview.campaign.closeCampaignModal.shared", {
                          defaultValue: "Partagé",
                        })
                      : t("peopleReview.campaign.closeCampaignModal.notShared", {
                          defaultValue: "Non partagé",
                        })
                  }
                />

                <Flex flex={1}>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t("peopleReview.campaign.closeCampaignModal.generalComment", {
                      defaultValue: "Commentaire général du coordinateur",
                    })}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDirection="row-reverse">
            <DSButton
              buttonSize="S"
              emphasis="Mid"
              onClick={() => setIsEdit(true)}
              label={t("peopleReview.campaign.closeCampaignModal.edit", {
                defaultValue: "Modifier",
              })}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export const MonthlyPlaceholder = () => {
  return (
    <svg
      width="375"
      height="180"
      viewBox="0 0 375 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <g filter="url(#filter0_d_4815_10618)">
          <g clipPath="url(#clip0_4815_10618)">
            <path
              d="M180.5 68C180.5 54.7452 191.245 44 204.5 44H338.5C351.755 44 362.5 54.7452 362.5 68V131C362.5 144.255 351.755 155 338.5 155H204.5C191.245 155 180.5 144.255 180.5 131V68Z"
              fill="white"
            />
            <rect
              x="196.5"
              y="64.75"
              width="48"
              height="48"
              rx="3"
              fill="url(#paint0_linear_4815_10618)"
            />
            <rect
              x="261.5"
              y="82.25"
              width="85"
              height="13"
              rx="3"
              fill="url(#paint1_linear_4815_10618)"
            />
            <rect
              x="196.5"
              y="133.5"
              width="150"
              height="25.5"
              rx="3"
              fill="url(#paint2_linear_4815_10618)"
            />
          </g>
        </g>
        <g filter="url(#filter1_d_4815_10618)">
          <g clipPath="url(#clip1_4815_10618)">
            <path
              d="M12.5 68C12.5 54.7452 23.2452 44 36.5 44H170.5C183.755 44 194.5 54.7452 194.5 68V131C194.5 144.255 183.755 155 170.5 155H36.5C23.2452 155 12.5 144.255 12.5 131V68Z"
              fill="white"
            />
            <rect
              x="28.5"
              y="64.75"
              width="48"
              height="48"
              rx="3"
              fill="url(#paint3_linear_4815_10618)"
            />
            <rect
              x="93.5"
              y="82.25"
              width="85"
              height="13"
              rx="3"
              fill="url(#paint4_linear_4815_10618)"
            />
            <rect
              x="28.5"
              y="133.5"
              width="150"
              height="25.5"
              rx="3"
              fill="url(#paint5_linear_4815_10618)"
            />
          </g>
        </g>
        <rect x="12.5" y="65" width="350" height="115" fill="url(#paint6_linear_4815_10618)" />
        <g filter="url(#filter2_d_4815_10618)">
          <g clipPath="url(#clip2_4815_10618)">
            <path
              d="M91.5 32C91.5 18.7452 102.245 8 115.5 8H249.5C262.755 8 273.5 18.7452 273.5 32V95C273.5 108.255 262.755 119 249.5 119H115.5C102.245 119 91.5 108.255 91.5 95V32Z"
              fill="white"
            />
            <rect
              x="107.5"
              y="28.75"
              width="48"
              height="48"
              rx="3"
              fill="url(#paint7_linear_4815_10618)"
            />
            <rect
              x="172.5"
              y="46.25"
              width="85"
              height="13"
              rx="3"
              fill="url(#paint8_linear_4815_10618)"
            />
            <rect
              x="107.5"
              y="97.5"
              width="150"
              height="25.5"
              rx="3"
              fill="url(#paint9_linear_4815_10618)"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_4815_10618"
          x="168.5"
          y="35"
          width="206"
          height="135"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4815_10618" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4815_10618"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_4815_10618"
          x="0.5"
          y="35"
          width="206"
          height="135"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4815_10618" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4815_10618"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_4815_10618"
          x="79.5"
          y="-1"
          width="206"
          height="135"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4815_10618" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4815_10618"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4815_10618"
          x1="196.5"
          y1="64.75"
          x2="254.274"
          y2="85.3897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E8EB" />
          <stop offset="0.460638" stopColor="#F6F7F8" />
          <stop offset="0.658558" stopColor="#E5E8EB" />
          <stop offset="1" stopColor="#F6F7F8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4815_10618"
          x1="261.5"
          y1="82.25"
          x2="279.369"
          y2="123.989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E8EB" />
          <stop offset="0.460638" stopColor="#F6F7F8" />
          <stop offset="0.658558" stopColor="#E5E8EB" />
          <stop offset="1" stopColor="#F6F7F8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4815_10618"
          x1="196.5"
          y1="133.5"
          x2="234.089"
          y2="212.492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E8EB" />
          <stop offset="0.460638" stopColor="#F6F7F8" />
          <stop offset="0.658558" stopColor="#E5E8EB" />
          <stop offset="1" stopColor="#F6F7F8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4815_10618"
          x1="28.5"
          y1="64.75"
          x2="86.2744"
          y2="85.3897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E8EB" />
          <stop offset="0.460638" stopColor="#F6F7F8" />
          <stop offset="0.658558" stopColor="#E5E8EB" />
          <stop offset="1" stopColor="#F6F7F8" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4815_10618"
          x1="93.5"
          y1="82.25"
          x2="111.369"
          y2="123.989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E8EB" />
          <stop offset="0.460638" stopColor="#F6F7F8" />
          <stop offset="0.658558" stopColor="#E5E8EB" />
          <stop offset="1" stopColor="#F6F7F8" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4815_10618"
          x1="28.5"
          y1="133.5"
          x2="66.0892"
          y2="212.492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E8EB" />
          <stop offset="0.460638" stopColor="#F6F7F8" />
          <stop offset="0.658558" stopColor="#E5E8EB" />
          <stop offset="1" stopColor="#F6F7F8" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_4815_10618"
          x1="187.5"
          y1="-31.1475"
          x2="187.5"
          y2="144.18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFA" stopOpacity="0" />
          <stop offset="1" stopColor="#FAFAFA" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_4815_10618"
          x1="107.5"
          y1="28.75"
          x2="165.274"
          y2="49.3897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E8EB" />
          <stop offset="0.460638" stopColor="#F6F7F8" />
          <stop offset="0.658558" stopColor="#E5E8EB" />
          <stop offset="1" stopColor="#F6F7F8" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_4815_10618"
          x1="172.5"
          y1="46.25"
          x2="190.369"
          y2="87.9895"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E8EB" />
          <stop offset="0.460638" stopColor="#F6F7F8" />
          <stop offset="0.658558" stopColor="#E5E8EB" />
          <stop offset="1" stopColor="#F6F7F8" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_4815_10618"
          x1="107.5"
          y1="97.5"
          x2="145.089"
          y2="176.492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E8EB" />
          <stop offset="0.460638" stopColor="#F6F7F8" />
          <stop offset="0.658558" stopColor="#E5E8EB" />
          <stop offset="1" stopColor="#F6F7F8" />
        </linearGradient>
        <clipPath id="clip0_4815_10618">
          <path
            d="M180.5 68C180.5 54.7452 191.245 44 204.5 44H338.5C351.755 44 362.5 54.7452 362.5 68V131C362.5 144.255 351.755 155 338.5 155H204.5C191.245 155 180.5 144.255 180.5 131V68Z"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_4815_10618">
          <path
            d="M12.5 68C12.5 54.7452 23.2452 44 36.5 44H170.5C183.755 44 194.5 54.7452 194.5 68V131C194.5 144.255 183.755 155 170.5 155H36.5C23.2452 155 12.5 144.255 12.5 131V68Z"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip2_4815_10618">
          <path
            d="M91.5 32C91.5 18.7452 102.245 8 115.5 8H249.5C262.755 8 273.5 18.7452 273.5 32V95C273.5 108.255 262.755 119 249.5 119H115.5C102.245 119 91.5 108.255 91.5 95V32Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

import { forwardRef, useEffect, useMemo, useState, RefObject } from "react";

import Highcharts from "highcharts";
import HighchartsReact, { HighchartsReactRefObject } from "highcharts-react-official";

import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { DSButton, Flex, DSTooltip } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";

import { QuestionData, DashboardCardData } from "../../types";

import styles from "./Histogram.module.scss";

import { SortTopToBottom } from "./Icons/SortTopBottom";
import { SortBottomToTop } from "./Icons/SortBottomTop";
import { createHistogramOptions } from "./utils";

export type HistogramChartProps = {
  data: QuestionData;
  card: DashboardCardData;
  loading: boolean;
  isPaginated: boolean;
  allData?: boolean;
};

const PAGE_SIZE = 8;

export const HistogramChart = forwardRef(
  (
    { data, card, loading, isPaginated, allData }: HistogramChartProps,
    ref: RefObject<HighchartsReactRefObject>
  ) => {
    const { t, i18n } = useTranslation();
    const { settings } = useSettings();

    const [page, setPage] = useState(0);
    const [sort, setSort] = useState<"asc" | "desc">(data.default_sort?.direction ?? "asc");

    const isMoneyValue = card.question_meta.axes[0].data_type === "money";
    const { currency } = settings;

    useEffect(() => {
      if (!ref.current?.chart) {
        return;
      }

      if (!loading) ref.current?.chart?.hideLoading();
      else ref.current?.chart?.showLoading();
    }, [loading, ref]);

    const pageNumber = Math.ceil(data.data.length / PAGE_SIZE);

    const options = useMemo(
      () =>
        createHistogramOptions(
          !allData ? "page" : "all",
          sort,
          styles,
          currency,
          data,
          card,
          t,
          i18n
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [card, data, isPaginated, sort, isMoneyValue, currency]
    );

    useEffect(() => {
      if (!isPaginated) {
        return;
      }

      if (page >= pageNumber || page < 0) {
        setPage(0);
        return;
      }

      if (!ref.current?.chart) {
        return;
      }

      const isInverted = sort === "asc";

      if (!isInverted) {
        const start = page * PAGE_SIZE;
        const end = start + PAGE_SIZE;
        ref.current.chart.axes[0].setExtremes(start, Math.min(end, data.data.length) - 1);
      } else {
        let end = data.data.length - page * PAGE_SIZE;
        let start = end - PAGE_SIZE;

        if (start < 0) {
          end -= start;
          start = 0;
        }

        ref.current.chart.axes[0].setExtremes(
          Math.max(start, 0),
          Math.min(end, data.data.length) - 1
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, data, sort, isPaginated]);

    const handlePageUp = () => {
      if (page + 1 < pageNumber) {
        setPage((page) => page + 1);
      }
    };

    const handlePageDown = () => {
      if (page - 1 >= 0) {
        setPage((page) => page - 1);
      }
    };

    const canSort = !card.question_meta.axes.some(
      (axis) => axis.id === "training_plan_employee_INSEE_age_slice"
    );

    return (
      <Flex column style={{ height: "100%" }}>
        <HighchartsReact
          ref={ref}
          highcharts={Highcharts}
          containerProps={{ style: { height: "100%" } }}
          options={options}
        />

        <Flex
          row
          className={styles.footer}
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          {canSort ? (
            <DSTooltip
              tooltipClassName={styles.sortActionTooltip}
              label={t("dashboard.training.histogram.action.sort", {
                defaultValue: "Inverser l'ordre d'affichage",
              })}
            >
              <DSButton
                buttonSize="M"
                emphasis="Low"
                iconOnly
                icon={sort === "asc" ? <SortBottomToTop /> : <SortTopToBottom />}
                onClick={() => setSort((sort) => (sort === "asc" ? "desc" : "asc"))}
              />
            </DSTooltip>
          ) : (
            <div />
          )}

          {isPaginated && (
            <Flex
              className={styles.pagination}
              style={{ justifyContent: "end", alignItems: "center" }}
            >
              <DSButton
                emphasis="Low"
                buttonSize="M"
                disabled={page === 0}
                onClick={handlePageDown}
                iconOnly
                icon={<ChevronLeft />}
              />
              <span>
                {page + 1}/{pageNumber}
              </span>
              <DSButton
                emphasis="Low"
                buttonSize="M"
                disabled={page === pageNumber - 1}
                onClick={handlePageUp}
                iconOnly
                icon={<ChevronRight />}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  }
);

import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { ListUtils } from "@skillup/shared-utils";

import { type FilterProp, type FilterProps } from "./types";

export type UseFilterProp<Config extends ListUtils.FilterConfigurationMap> = {
  [key in keyof Config]: FilterProps<Config[key]["type"]> & {
    initialValue?: ListUtils.FilterValueType[Config[key]["type"]];
  };
};

export const useFilters = <Config extends ListUtils.FilterConfigurationMap>(
  config: Config,
  filters: UseFilterProp<Config>
) => {
  const initialFilters = useMemo(() => {
    return Object.keys(config).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          type: config[key].type,
          visibilityMode: filters[key]?.visibilityMode,
          defaultValue: filters[key]?.defaultValue,
          options: filters[key]?.options,
          value: filters[key]?.initialValue,
          operator: ListUtils.FilterOperator.CONTAINS,
          label: config[key]?.label,
          placeholder: filters[key]?.placeholder,
          sortPosition: filters[key]?.sortPosition,
        },
      }),
      {} as FilterProp<Config>
    );
  }, [config, filters]);
  const [filterValues, setFilterValues] = useState<ListUtils.FilterValues<Config>>(
    Object.keys(config)
      .filter(
        (filterId) =>
          initialFilters[filterId] &&
          initialFilters[filterId].value !== undefined &&
          initialFilters[filterId].value !== null
      )
      .reduce(
        (acc, filterId) => ({
          ...acc,
          [filterId]: {
            type: initialFilters[filterId].type,
            value: initialFilters[filterId].value,
            operator: initialFilters[filterId].operator,
          },
        }),
        {} as FilterProp<Config>
      )
  );

  return [initialFilters, filterValues, setFilterValues] as [
    typeof initialFilters,
    typeof filterValues,
    typeof setFilterValues,
  ];
};

export const useUrlSyncedFilters = <Config extends ListUtils.FilterConfigurationMap>(
  config: Config,
  initialValues: UseFilterProp<Config>
) => {
  const history = useHistory();
  const location = useLocation();

  /**
   * Populate initValues from url
   */
  const filtersConfig = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    return Object.keys(config).reduce<UseFilterProp<Config>>((acc, filterId) => {
      return {
        ...acc,
        ...(searchParams.has(filterId) && {
          [filterId]: {
            ...acc[filterId],
            initialValue:
              config[filterId].type === "multiselect"
                ? searchParams.getAll(filterId)
                : searchParams.get(filterId),
          },
        }),
      };
    }, initialValues);
  }, [config]);

  const [filters, filterValues, setFilterValues] = useFilters(config, filtersConfig);

  /**
   * Update url's searchParams according to filters
   * This function should fire on `values` or `config` change
   */
  useEffect(() => {
    const searchParams = new URLSearchParams();

    for (const filterId in config) {
      if (
        config[filterId].type === "multiselect" &&
        Array.isArray(filterValues[filterId]?.value) &&
        filterValues[filterId].value.length > 0 &&
        filterValues[filterId].value.every(Boolean)
      ) {
        filterValues[filterId].value.forEach((value) => searchParams.append(filterId, value));
      } else if (
        config[filterId].type === "text" &&
        filterValues[filterId]?.value !== undefined &&
        filterValues[filterId]?.value !== null &&
        filterValues[filterId]?.value !== ""
      ) {
        searchParams.set(filterId, filterValues[filterId].value.toString());
      }
    }

    searchParams.sort();
    const currentSearchParams = new URLSearchParams(history.location.search);
    currentSearchParams.sort();

    if (currentSearchParams.toString() !== searchParams.toString()) {
      history.replace({ search: searchParams.toString() });
    }
  }, [filterValues, config]);

  return [filters, filterValues, setFilterValues] as [
    typeof filters,
    typeof filterValues,
    typeof setFilterValues,
  ];
};

import { Either } from "@skillup/monads";
import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export async function editCampaignFeedbackByCampaignID({
  campaignID,
  isCommentSharedToManager,
  sharedToManagerActionIds,
  sharedToManagerCoreHRFieldsIds,
}: {
  campaignID: string;
  isCommentSharedToManager: boolean;
  sharedToManagerActionIds: string[];
  sharedToManagerCoreHRFieldsIds: string[];
}) {
  try {
    const response = await buildRequest<CampaignsRoutes.EditCampaignFeedbackByCampaignID>({
      method: "PUT",
      params: { campaignID },
      path: "/campaigns/{campaignID}/feedback",
      payload: {
        isCommentSharedToManager,
        sharedToManagerActionIds,
        sharedToManagerCoreHRFieldsIds,
      },
      target: "PEOPLE_REVIEW",
    })();

    return Either.right(response);
  } catch (error) {
    return Either.left(error);
  }
}

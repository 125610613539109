import { useMemo, useRef } from "react";
import { HighchartsReactRefObject } from "highcharts-react-official";

import { Flex } from "@skillup/ui";

import { CardProps } from "./Card";
import { PieChart } from "../Charts/Pie";
import { CardMenu } from "../CardMenu";

import styles from "./DetailedIndicator.module.scss";
import { HistogramChart } from "../Charts/Histogram";
import useTranslation from "hooks/useTranslation";

export const DetailedIndicator = ({ data: rawData, loading, card }: CardProps) => {
  const { t } = useTranslation();
  const isPieChart =
    card.charts.includes("pie") &&
    card.question_meta.axes[1]?.fingerprint?.distinct_values &&
    card.question_meta.axes[1].fingerprint.distinct_values.length < 4;
  const isPaginated = !isPieChart && rawData?.data.length > 8;
  const graphRef = useRef<HighchartsReactRefObject>(null);

  const data: typeof rawData = useMemo(() => {
    if (card.question_meta.axes[1]?.id === "training_plan_plan_completion_phase") {
      if (rawData && rawData.data) {
        return {
          ...rawData,
          data: rawData.data.map((row) => {
            return [row[0], t(row[1])];
          }),
        };
      }
    }
    return rawData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData]);

  return (
    <Flex column className={styles.detailedIndicator}>
      <Flex row className={styles.header}>
        <div className={styles.title}>{card.short_name}</div>
        {data && <CardMenu data={data} card={card} graphRef={graphRef} />}
      </Flex>

      {data && isPieChart && <PieChart ref={graphRef} loading={loading} data={data} card={card} />}
      {data && !isPieChart && (
        <HistogramChart
          ref={graphRef}
          loading={loading}
          data={data}
          card={card}
          isPaginated={isPaginated}
        />
      )}
    </Flex>
  );
};

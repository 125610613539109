import Acta from "utils/Acta";
import storage from "services/storage";

import type { Credentials } from "types/api";
import { useState } from "react";
import { getInitials } from "@skillup/shared-utils";
export type { Credentials };

const getUserData = (credentials: Credentials | null = null) => {
  if (credentials) {
    return credentials;
  } else {
    return Acta.getState("userData");
  }
};

const isRF = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.userScope?.includes?.("admin");
};

const isTrainer = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.userScope?.includes?.("trainer");
};

const isAdminWithoutIntras = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.userScope?.includes?.("admin-without-intras");
};

const isTrainerWithoutIntras = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.userScope?.includes?.("trainer-without-intras");
};

const isTrainerQHSE = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.userScope?.includes?.("trainer-qhse");
};

const isManager = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.userScope?.includes?.("manager");
};

const isInterviewManager = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.userScope?.includes?.("interview-manager");
};

const isRegularUser = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!(
    userData &&
    userData.uuid &&
    userData.activeCompany &&
    !isManager(credentials) &&
    !isRF(credentials)
  );
};

const isOF = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeTrainingOrganization?.userScope?.includes?.("admin");
};

const isDemoUser = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!(
    userData &&
    userData.uuid &&
    userData.activeCompany &&
    userData.activeCompany.uuid === "b2359493-a019-4627-82e7-a8751ffcebaf"
  );
};

const canCreateInterviewCampaignForDemo = (credentials: Credentials | null = null): boolean => {
  const allowedCompanies = [
    "6f1b6ba5-d3d9-49a7-abe2-059cf48aecb3", // PeopleSpheres
  ];

  const userData = getUserData(credentials);
  return allowedCompanies.includes(userData?.activeCompany?.uuid);
};

const canCreateCampaign = (credentials: Credentials | null = null): boolean => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.features?.includes?.("campaign-creation");
};

const isSkillupAdmin = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!(userData && userData.scope && userData.scope.includes("admin"));
};

const isSkillupDeveloper = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!(userData?.scope?.includes("admin") && userData?.scope?.includes("developer"));
};

const isImpostor = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!userData?.impostor;
};

const isUserless = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!(!userData?.uuid && userData?.activeCompany);
};

const isCompanyUser = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);

  return !!(userData?.uuid && userData?.activeCompany);
};

const isPortalEnabled = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.portalEnabled;
};

const isPortalSearchDisabled = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!(
    userData?.activeCompany?.portalEnabled && userData?.activeCompany?.portalSearchDisabled
  );
};

const hasPeopleReviewEnabled = (credentials: Credentials | null = null): boolean => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.features?.includes?.("people-review");
};

const hasInterviewsEnabled = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.features?.some((feat: string) => feat.includes("interviews"));
};

const hasSkillsEnabled = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.features?.some((feat: string) => feat.includes("skills"));
};

const canAccessCompetencesModule = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);

  return !!userData?.activeCompany?.features?.includes?.("skills-jobs");
};

const hasTrainingsEnabled = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);

  return !!userData?.activeCompany?.features?.some((feat: string) => feat.includes("trainings"));
};

const hasReglementaireEnabled = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);

  return !!userData?.activeCompany?.features?.includes?.("reglementaire");
};

const hasInterviewDashboardEnabled = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);

  return !!userData?.activeCompany?.features?.includes?.("interview-dashboard");
};

const hasTrainingDashboardEnabled = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);

  return !!userData?.activeCompany?.features?.includes?.("training-dashboard");
};

const hasTrainingDashboardV2Enabled = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);

  return !!userData?.activeCompany?.features?.includes?.("training-dashboard-v2");
};

const hasMVPTrainingDashboardEnabled = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);

  return !!userData?.activeCompany?.features?.includes?.("mvp-dashboard-training");
};

const hasHotReviewsEnabled = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);

  return !!userData?.activeCompany?.features?.includes?.("trainings-hot-evaluations");
};

const hasColdReviewsEnabled = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);

  return !!userData?.activeCompany?.features?.includes?.("trainings-cold-evaluations");
};

const hasEmployeeV3Enabled = (credentials: Credentials | null = null): boolean => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.features?.includes?.("employee-v3");
};

const hasJobSuggestionsEnabled = (credentials: Credentials | null = null): boolean => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.features?.includes?.("job-suggestions");
}

const hasFieldsV2Disable = (credentials: Credentials | null = null): boolean => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.features?.includes?.("custom-training-fields");
};

const getSchedules = ({ archived = false } = {}, credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);

  const schedules = userData?.activeCompany?.schedules;
  if (!schedules) return [];

  const sortedSchedules = schedules.sort((a, b) => {
    if (a.active) return -1;
    else if (b.active) return 1;

    if (a.createdAt > b.createdAt) return -1;
    else if (a.createdAt < b.createdAt) return 1;
    return 0;
  });

  if (archived) {
    return sortedSchedules;
  }

  return sortedSchedules.filter((s) => !s.archivedAt);
};

const getActiveSchedule = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);

  const schedules = userData?.activeCompany?.schedules;
  if (!schedules) return null;

  return schedules.find((s) => s.active);
};

const defaultGroup = { name: "Groupe par défaut", uuid: null };

const getGroups = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);

  const groups = userData?.activeCompany?.groups;
  if (!groups) return [defaultGroup];

  return [defaultGroup, ...groups];
};

const getUserGroup = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);

  const userGroup = userData?.activeCompany?.userGroup;
  if (!userGroup) return defaultGroup;
  if (!userGroup.uuid) return { ...defaultGroup, ...userGroup };

  return userGroup;
};

type UserArea = {
  uuid: string;
  name: string;
};

const getAreas = (credentials: Credentials | null = null): Array<UserArea> => {
  const userData = getUserData(credentials);

  const areas = userData?.activeCompany?.accessControlList?.userAreas;
  return areas;
};

type UserNameData = {
  firstName?: string;
  lastName?: string;
  fullName?: string;
};

function computeFullName<User extends UserNameData>(user: User): string {
  const { firstName, lastName } = user;
  return `${(firstName ?? "").trim()} ${(lastName ?? "").trim()}`.trim();
}

function getFullName<User extends UserNameData>(user: User): string {
  const { fullName } = user;
  return fullName ?? computeFullName(user);
}

function getFirstLetters<User extends UserNameData>(user: User): string {
  const { firstName, lastName } = user;
  return (
    (firstName ?? "").trim().charAt(0).toUpperCase() +
    (lastName ?? "").trim().charAt(0).toUpperCase()
  );
}

function getUserInitials<User extends UserNameData>(user: User): string {
  return getInitials({
    firstName: user.firstName,
    lastName: user.lastName,
    fullName: user.fullName,
  });
}

const getUserCompanyUuid = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return userData.activeCompany.uuid;
};

const getUserCompanyName = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  let companyName = "Not found";
  if (userData.useractiveCompany && userData.activeCompany.name) {
    companyName = userData.activeCompany.name;
  } else if (userData.activeTrainingOrganization) {
    companyName = userData.activeTrainingOrganization.name;
  }
  return companyName;
};

const getUserLvl = (credentials: Credentials | null = null) => {
  let userLvl = "Not found";
  if (isSkillupAdmin(credentials) || isSkillupDeveloper(credentials)) {
    userLvl = "SkillupUser"; // skillup
  } else if (isRF(credentials)) {
    userLvl = "RH"; //  RH
  } else if (isManager(credentials)) {
    userLvl = "Manager"; // Userless
  } else if (isRegularUser(credentials)) {
    userLvl = "User"; // Regular user
  } else if (isUserless(credentials)) {
    userLvl = "Userless"; // Userless
  } else if (isOF(credentials)) {
    userLvl = "TrainingOrganizationUser"; // Training org user
  }
  return userLvl;
};

const isCompanyWithTax = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return Boolean(userData?.activeCompany?.withTax);
};

const getCurrentSchedule = (credentials: Credentials | null = null) => {
  const scheduleUuid = sessionStorage.getItem("scheduleUuid");
  const activeSchedule = getActiveSchedule(credentials);
  const schedules = getSchedules({}, credentials);
  return scheduleUuid || activeSchedule?.uuid || schedules[0]?.uuid;
};

const setCurrentSchedule = (scheduleUuid) => {
  sessionStorage.setItem("scheduleUuid", scheduleUuid);
  window.dispatchEvent(new CustomEvent("scheduleChanged", { detail: scheduleUuid }));
  return scheduleUuid;
};

const getCurrentLanguage = () => {
  const userData = storage.get("_skillup_userData");
  return userData.currentLanguage || userData.defaultLanguage || "fr";
};

const getUserUuid = (credentials: Credentials | null = null) => {
  const userData = getUserData(credentials);
  return userData.uuid;
};

export {
  canAccessCompetencesModule,
  computeFullName,
  getActiveSchedule,
  getCurrentSchedule,
  setCurrentSchedule,
  getFirstLetters,
  getFullName,
  getGroups,
  getSchedules,
  getUserData,
  getUserGroup,
  getUserInitials,
  getUserUuid,
  getAreas,
  getCurrentLanguage,
  getUserCompanyName,
  getUserCompanyUuid,
  getUserLvl,
  hasInterviewsEnabled,
  hasPeopleReviewEnabled,
  hasSkillsEnabled,
  hasReglementaireEnabled,
  hasInterviewDashboardEnabled,
  hasTrainingDashboardEnabled,
  hasTrainingDashboardV2Enabled,
  hasMVPTrainingDashboardEnabled,
  hasTrainingsEnabled,
  hasHotReviewsEnabled,
  hasColdReviewsEnabled,
  hasEmployeeV3Enabled,
  hasJobSuggestionsEnabled,
  isCompanyUser,
  isDemoUser,
  canCreateInterviewCampaignForDemo,
  canCreateCampaign,
  isImpostor,
  isInterviewManager,
  isManager,
  isOF,
  isPortalEnabled,
  isPortalSearchDisabled,
  isRegularUser,
  isRF,
  isSkillupAdmin,
  isSkillupDeveloper,
  isTrainer,
  isAdminWithoutIntras,
  isTrainerWithoutIntras,
  isTrainerQHSE,
  isUserless,
  isCompanyWithTax,
};

export default {
  canAccessCompetencesModule,
  computeFullName,
  getActiveSchedule,
  getCurrentSchedule,
  setCurrentSchedule,
  getFirstLetters,
  getFullName,
  getGroups,
  getSchedules,
  getUserData,
  getUserGroup,
  getUserInitials,
  getUserUuid,
  getAreas,
  getCurrentLanguage,
  getUserCompanyName,
  getUserCompanyUuid,
  getUserLvl,
  hasInterviewsEnabled,
  hasPeopleReviewEnabled,
  hasSkillsEnabled,
  hasTrainingsEnabled,
  hasReglementaireEnabled,
  hasInterviewDashboardEnabled,
  hasTrainingDashboardEnabled,
  hasTrainingDashboardV2Enabled,
  hasMVPTrainingDashboardEnabled,
  hasHotReviewsEnabled,
  hasColdReviewsEnabled,
  hasFieldsV2Disable,
  isCompanyUser,
  isDemoUser,
  canCreateInterviewCampaignForDemo,
  canCreateCampaign,
  isImpostor,
  isInterviewManager,
  isManager,
  isOF,
  isPortalEnabled,
  isPortalSearchDisabled,
  isRegularUser,
  isRF,
  isSkillupAdmin,
  isSkillupDeveloper,
  isTrainer,
  isAdminWithoutIntras,
  isTrainerWithoutIntras,
  isTrainerQHSE,
  isUserless,
  isCompanyWithTax,
};

export function useCredentials() {
  const [credentials] = useState<Credentials>(Acta.getState("userData"));
  return credentials;
}

import { useTranslation } from "react-i18next";
import { useParams, useHistory, useLocation, generatePath } from "react-router-dom";
import { useRef, useMemo, useState, Dispatch, useEffect, RefObject, SetStateAction } from "react";

import { MdRecentActors } from "react-icons/md";

import GridOnIcon from "@mui/icons-material/GridOn";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { Flex } from "@skillup/design-system";
import {
  Loader,
  DSFilters,
  FilterRef,
  DSProgressBar,
  DSDropdownItem,
  TypeProgressBar,
  ToggleButtonGroup,
  DSDropdownDivider,
  useUrlSyncedFilters,
} from "@skillup/ui";

import useAreas from "hooks/useAreas";
import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import { getCampaignDetails } from "services/peopleReview/useCampaignDetails/getCampaignDetails";
import { deleteCampaign } from "services/peopleReview/useCampaignDetails/deleteCampaign.mutation";
import { getCampaignManagers } from "services/peopleReview/useCampaignDetails/getCampaignManagers";
import {
  startCampaign,
  pauseCampaign,
  resumeCampaign,
} from "services/peopleReview/useCampaignDetails/updateCampaignState";

import { PeopleReviewRoutes } from "../router";
import { TalentGrid } from "../../components/TalentGrid/TalentGrid";
import { BaseUrl as PeopleReviewBaseUrl } from "../../pages/router";
import { View, views, Campaign, getFiltersConfig } from "../../types";
import { CloseCampaignModal } from "../../components/CloseCampaignModal/CloseCampaignModal";
import { CampaignFeedbackModal } from "../../components/CampaignFeedbackModal/CampaignFeedbackModal";
import { AddEmployeeToCampaignModal } from "../../components/AddEmployeeToCampaignModal/AddEmployeeToCampaignModal";
import {
  TalentMatrix,
  PausedStatus,
  NotStartedStatus,
  ObserversCoordinatorsModal,
} from "../../components";
import { ManagerPreparationWarningModal } from "../../components/ManagerPreparationWarningModal/ManagerPreparationWarningModal";

import styles from "./PPRCampaign.module.scss";
import peopleRevieWStyles from "../../people-review.module.scss";

export function PPRCampaign() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const history = useHistory();
  const { campaignID } = useParams<{ campaignID: string }>();
  const [activeModal, setActiveModal] = useState<Modal>(null);
  const drawer = useRef<HTMLDivElement>(null);
  const { data: campaign, isLoading } = useQuery(["people-reviews-campaign", campaignID], () =>
    getCampaignDetails(campaignID)
  );

  const { isLoading: isResumeLoading, mutate: resume } = useMutation(resumeCampaign, {
    onSuccess: () => {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign", campaignID]);
    },
  });
  const { isLoading: isPauseLoading, mutate: pause } = useMutation(pauseCampaign, {
    onSuccess: () => {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign", campaignID]);
    },
  });
  const { isLoading: isDeleteLoading, mutate: delete_ } = useMutation(deleteCampaign, {
    onSuccess: () => {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign", campaignID]);
      history.replace(PeopleReviewRoutes.PPRCampaignsList);
    },
  });
  const { isLoading: isStartLoading, mutate: start } = useMutation(startCampaign, {
    onSuccess: () => {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign", campaignID]);
    },
  });

  if (isLoading) {
    return <Loader />;
  } else if (!campaign) {
    return null;
  }

  return (
    <DSLayout
      title={campaign.titleForHR}
      className={peopleRevieWStyles.layout}
      parent={{
        title: t("peopleReview.title", {
          defaultValue: "People Review",
        }),
        titleUrl: generatePath(PeopleReviewRoutes.PPRCampaignsList),
      }}
      header={
        <Flex paddingTop="xxs" paddingBottom="s" paddingHorizontal="s" className={styles.header}>
          <Flex width="30rem" marginLeft="xl">
            <ProgressBar campaign={campaign} />
          </Flex>
        </Flex>
      }
      leftItem={
        <Flex height="37px" alignItems="flex-end">
          {campaign.state === "not_started" && <NotStartedStatus isDark={false} />}
          {campaign.state === "paused" && <PausedStatus isDark={false} />}
        </Flex>
      }
      layouts={[
        {
          dropdownContent: [
            <DSDropdownItem
              disabled
              key="rename-campaign"
              onClick={() => {}}
              label={t("peopleReview.button.renameCampaign", {
                defaultValue: "Renommer la campagne",
              })}
              tooltipLabel={t("peopleReview.button.soonAvailable", {
                defaultValue: "Bientôt disponible",
              })}
            />,

            <DSDropdownItem
              disabled
              key="check-model"
              onClick={() => {}}
              label={t("peopleReview.button.checkModel", {
                defaultValue: "Consulter le modèle utilisé",
              })}
              tooltipLabel={t("peopleReview.button.soonAvailable", {
                defaultValue: "Bientôt disponible",
              })}
            />,

            <DSDropdownDivider key="divider" />,

            <DSDropdownItem
              key="manage-reviews"
              disabled={campaign.state === "closed"}
              onClick={() => setActiveModal("add-employee-modal-to-campaign")}
              label={t("peopleReview.button.addReview", {
                defaultValue: "Ajouter des collaborateurs",
              })}
              tooltipLabel={
                campaign.state === "closed" &&
                t("peopleReview.button.addReview.disabledReason", {
                  defaultValue: "Impossible d'ajouter des collaborateurs sur une campagne terminée",
                })
              }
            />,

            campaign.permissions["manage-coordinators-observers"].isVisible && (
              <DSDropdownItem
                key="manage-coordinators-observers"
                disabled={!campaign.permissions["manage-coordinators-observers"].isEnabled}
                onClick={() => setActiveModal("manage-coordinators-observers")}
                label={t("peopleReview.button.manageCoordinatorsAndObservers", {
                  defaultValue: "Gestion des responsables",
                })}
                tooltipLabel={
                  campaign.permissions["manage-coordinators-observers"].isEnabled
                    ? undefined
                    : t("peopleReview.button.soonAvailable", {
                        defaultValue: "Bientôt disponible",
                      })
                }
              />
            ),

            <DSDropdownItem
              disabled
              key="manage-notifications"
              onClick={() => {}}
              tooltipLabel={t("peopleReview.button.soonAvailable", {
                defaultValue: "Bientôt disponible",
              })}
              label={t("peopleReview.button.manageNotifications", {
                defaultValue: "Paramétrer les notifications",
              })}
            />,

            campaign.permissions["edit-feedback"].isVisible && (
              <DSDropdownItem
                key="campaign-feedback-settings"
                onClick={() => setActiveModal("campaign-feedback")}
                label={t("peopleReview.button.campaignFeedbackSettings", {
                  defaultValue: "Configurer les éléments partagés lors de la clôture",
                })}
              />
            ),

            (campaign.permissions.close.isVisible ||
              campaign.permissions.resume.isVisible ||
              campaign.permissions.pause.isVisible) && <DSDropdownDivider key="divider-2" />,

            campaign.permissions.resume.isVisible && (
              <DSDropdownItem
                key="resume-campaign"
                disabled={!campaign.permissions.resume.isEnabled || isResumeLoading}
                onClick={() => resume(campaignID)}
                label={t("peopleReview.button.resumeCampaign", {
                  defaultValue: "Reprendre la campagne",
                })}
                tooltipLabel={
                  campaign.permissions.resume.isEnabled
                    ? null
                    : t(campaign.permissions.resume.reasonKey, {
                        defaultValue:
                          "Vous n'avez pas les droits nécessaires pour reprendre cette campagne.",
                      })
                }
              />
            ),

            campaign.permissions.pause.isVisible && (
              <DSDropdownItem
                key="pause-campaign"
                disabled={!campaign.permissions.pause.isEnabled || isPauseLoading}
                onClick={() => pause(campaignID)}
                label={t("peopleReview.button.pauseCampaign", {
                  defaultValue: "Suspendre la campagne",
                })}
                tooltipLabel={
                  campaign.permissions.pause.isEnabled
                    ? null
                    : t(campaign.permissions.pause.reasonKey, {
                        defaultValue:
                          "Vous n'avez pas les droits nécessaires pour suspendre cette campagne.",
                      })
                }
              />
            ),

            campaign.permissions.close.isVisible && (
              <DSDropdownItem
                key="close-campaign"
                disabled={!campaign.permissions.close.isEnabled}
                onClick={() => setActiveModal("close-campaign")}
                label={t("peopleReview.button.closeCampaign", {
                  defaultValue: "Clôturer la campagne",
                })}
                tooltipLabel={
                  campaign.permissions.close.isEnabled
                    ? null
                    : t(campaign.permissions.close.reasonKey, {
                        defaultValue:
                          "Vous n'avez pas les droits nécessaires pour clôturer cette campagne.",
                      })
                }
              />
            ),

            campaign.permissions.delete.isVisible && (
              <DSDropdownItem
                key="delete-campaign"
                disabled={!campaign.permissions.delete.isEnabled || isDeleteLoading}
                onClick={() => delete_(campaignID)}
                label={t("peopleReview.button.deleteCampaign", {
                  defaultValue: "Supprimer la campagne",
                })}
                tooltipLabel={
                  campaign.permissions.delete.isEnabled
                    ? null
                    : t(campaign.permissions.delete.reasonKey, {
                        defaultValue:
                          "Vous n'avez pas les droits nécessaires pour supprimer cette campagne.",
                      })
                }
              />
            ),
          ],

          primaryButton: campaign.permissions.launch.isVisible && (
            <DSNewHeaderButton
              key="start-campaign"
              loading={isStartLoading}
              disabled={!campaign.permissions.launch.isEnabled || isStartLoading}
              label={t("peopleReview.button.launchCampaign", {
                defaultValue: "Démarrer la campagne",
              })}
              onClick={() => {
                if (campaign.hasManagerPreparation) {
                  setActiveModal("manager-preparation-warning");
                } else {
                  start(campaignID);
                }
              }}
              tooltip={
                campaign.permissions.launch.isEnabled
                  ? null
                  : t(campaign.permissions.launch.reasonKey, {
                      defaultValue:
                        "Vous n'avez pas les droits nécessaires pour démarrer cette campagne.",
                    })
              }
            />
          ),
        },
      ]}
    >
      <Flex width={"100%"} flexDirection="row">
        <Content drawer={drawer} campaign={campaign} />

        <div ref={drawer} className={styles.drawer} />
      </Flex>

      <Modals campaign={campaign} activeModal={activeModal} setActiveModal={setActiveModal} />
    </DSLayout>
  );
}

type ProgressBarProps = {
  campaign: Campaign;
};
function ProgressBar({ campaign }: ProgressBarProps) {
  const { t } = useTranslation();

  const total = campaign.reviewCount;
  const progressData: React.ComponentProps<typeof DSProgressBar>["data"] = [];

  if (campaign.arbitratedCount > 0) {
    progressData.push({
      color: "success" as const,
      label: t("people-review-campaign.arbitratedCount", {
        count: campaign.arbitratedCount,
        defaultValue: `Arbitrage réalisé pour {{count}} collaborateur${campaign.arbitratedCount > 1 ? "s" : ""}`,
      }),
      value: (campaign.arbitratedCount * 100) / total,
    });
  }

  if (campaign.hasManagerPreparation) {
    if (campaign.managerPrepDoneCount > 0) {
      progressData.push({
        color: "successLight" as const,
        label: t("people-review-campaign.hasManagerPreparation", {
          count: campaign.managerPrepDoneCount,
          defaultValue: `Préparation manager réalisée pour {{count}} collaborateur${campaign.managerPrepDoneCount > 1 ? "s" : ""}`,
        }),
        value: (campaign.managerPrepDoneCount * 100) / total,
      });
    }
    if (campaign.othersCount > 0) {
      progressData.push({
        color: "transparent" as const,
        label: t("people-review.campaignCard.othersCountWithManagerPrep", {
          count: campaign.othersCount,
          defaultValue: `En attente de préparation manager pour {{count}} collaborateur${campaign.othersCount > 1 ? "s" : ""}`,
        }),
        value: (campaign.othersCount * 100) / total,
      });
    }
  } else {
    if (campaign.othersCount > 0) {
      progressData.push({
        color: "transparent" as const,
        label: t("people-review.campaignCard.othersCount", {
          count: campaign.othersCount,
          defaultValue: `{{count}} collaborateur${campaign.othersCount > 1 ? "s" : ""} en attente d'arbitrage`,
        }),
        value: (campaign.othersCount * 100) / total,
      });
    }
  }

  return (
    <DSProgressBar
      thin
      border
      layout="segmented"
      data={progressData}
      showLegends={false}
      type={TypeProgressBar.PERCENTAGE}
      disabled={campaign.state === "closed" || campaign.state === "paused"}
    />
  );
}

type ContentProps = {
  campaign: Campaign;
  drawer: RefObject<null | HTMLDivElement>;
};
function Content({ campaign, drawer }: ContentProps) {
  const { t } = useTranslation();
  const { currentView } = useParams<{ currentView: View }>();
  const history = useHistory();
  const { allAreas } = useAreas();
  const location = useLocation();

  const { data: managers } = useQuery(["people-reviews-campaign-managers", campaign.id], () =>
    getCampaignManagers(campaign.id)
  );

  /**
   * We need to re-render `useUrlSyncedFilters` when `managers` is loaded
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const config = useMemo(() => getFiltersConfig(campaign), [campaign, managers]);

  const [filters, filterValues, setFilterValues] = useUrlSyncedFilters(config, {
    branch: {
      placeholder: t("people-review-campaign.filter.placeholder.branch", {
        defaultValue: "Direction",
      }),
    },
    coordinatorStatus: {
      options: [
        {
          label: t("peopleReview.review.coordinatorStatus.noManagerPrep", {
            defaultValue: "Sans préparation manager",
          }),
          value: "no_manager_prep",
        },
        {
          label: t("peopleReview.review.coordinatorStatus.managerPrep", {
            defaultValue: "Préparation manager",
          }),
          value: "manager_prep",
        },
        {
          label: t("peopleReview.review.coordinatorStatus.inProgress", {
            defaultValue: "En cours",
          }),
          value: "in_progress",
        },
        {
          label: t("peopleReview.review.coordinatorStatus.done", { defaultValue: "Arbitré" }),
          value: "done",
        },
      ],
      placeholder: t("people-review-campaign.filter.placeholder.coordinatorStatus", {
        defaultValue: "Statut",
      }),
      visibilityMode: "always",
    },
    division: {
      placeholder: t("people-review-campaign.filter.placeholder.division", {
        defaultValue: "Division",
      }),
    },
    email: {
      placeholder: t("people-review-campaign.filter.placeholder.email", { defaultValue: "Email" }),
    },
    firstName: {
      placeholder: t("people-review-campaign.filter.placeholder.firstName", {
        defaultValue: "Prénom",
      }),
    },
    fullName: {
      placeholder: t("people-review-campaign.filter.placeholder.fullName", {
        defaultValue: "Nom complet",
      }),
    },
    lastName: {
      placeholder: t("people-review-campaign.filter.placeholder.lastName", { defaultValue: "Nom" }),
    },
    ...(campaign.hasManagerPreparation && {
      manager: {
        options: (managers ?? []).map(({ uuid, fullName }) => ({ label: fullName, value: uuid })),
        placeholder: t("people-review-campaign.filter.placeholder.manager", {
          defaultValue: "Manager",
        }),
        visibilityMode: "always",
      },
    }),
    perimeters: {
      options: allAreas.map(({ uuid, name }) => ({ label: name, value: uuid })),
      placeholder: t("people-review-campaign.filter.placeholder.perimeters", {
        defaultValue: "Périmètres",
      }),
      visibilityMode: "always",
    },
    registrationNumber: {
      placeholder: t("people-review-campaign.filter.placeholder.registrationNumber", {
        defaultValue: "Matricule",
      }),
    },
    role: {
      placeholder: t("people-review-campaign.filter.placeholder.role", { defaultValue: "Rôle" }),
    },
    service: {
      placeholder: t("people-review-campaign.filter.placeholder.service", {
        defaultValue: "Service",
      }),
    },
    site: {
      placeholder: t("people-review-campaign.filter.placeholder.site", { defaultValue: "Site" }),
    },
    ...campaign.fields.reduce((acc, field) => {
      if (field.kind === "enumerable") {
        acc[field.uuid] = {
          options: field.scale.options.map(({ label, value }) => ({
            label,
            value: value.toString(),
          })),
          placeholder: field.label,
        };
      }
      return acc;
    }, {}),
    ...campaign.customFields.reduce((acc, { binding, label }) => {
      acc[binding] = {
        placeholder: label,
        /**
         * @todo: we could use the customUserField.options to build filter.options
         */
      };
      return acc;
    }, {}),
  });

  const filterRef = useRef<FilterRef>();

  // useEffect to check urlChange and update filterValues
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchParamsKeys = new Set(searchParams.keys());
    const isFilterValuesMatchURL = [...searchParamsKeys].every((searchParamsKey) => {
      return filterValues[searchParamsKey];
    });

    const URLSearchParamsNbr = new Set(searchParams.keys()).size;

    if (URLSearchParamsNbr !== Object.keys(filterValues).length || !isFilterValuesMatchURL) {
      Object.keys(config).forEach((filterId) => {
        if (searchParams.has(filterId)) {
          filterRef.current.addFilter(filterId);
          filterRef.current.setValue(filterId, {
            type: config[filterId].type,
            label: config[filterId].label,
            operator: "contains",
            value:
              config[filterId].type === "multiselect"
                ? searchParams.getAll(filterId)
                : searchParams.get(filterId),
          });
        } else {
          filterRef.current.setValue(filterId, null);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return (
    <Flex padding="s" width="100%" flexDirection="column" className={styles.peopleReviewPage}>
      <Flex gap="xs">
        <Flex height="34px" marginTop="m">
          <ToggleButtonGroup
            value={currentView}
            tooltipDirection="top"
            className={styles.toggle}
            onChange={(value) => {
              history.push({
                pathname: `${PeopleReviewBaseUrl}/campaigns/${campaign.id}/${value}`,
                search: location.search,
              });
            }}
            options={[
              {
                icon: <FormatListBulletedIcon />,
                label: t("people-review.campaign.view-toggle.grid", {
                  defaultValue: "Tableau d'arbitrage",
                }),
                value: views.grid,
              },
              {
                icon: <MdRecentActors size={"1.5rem"} />,
                label: t("people-review.campaign.view-toggle.detailed", {
                  defaultValue: "Matrice de positionnement - Mode détaillé",
                }),
                value: views.detailed,
              },
              {
                icon: <GridOnIcon />,
                label: t("people-review.campaign.view-toggle.matrix", {
                  defaultValue: "Matrice de positionnement - Répartition",
                }),
                value: views.matrix,
              },
            ]}
          />
        </Flex>

        <DSFilters
          t={t}
          ref={filterRef}
          config={config}
          filters={filters}
          translationPrefix="people-review-campaign"
          onChange={setFilterValues}
        />
      </Flex>

      {currentView === views.grid && <TalentGrid campaign={campaign} filterValues={filterValues} />}
      {currentView === views.detailed && (
        <TalentMatrix
          drawer={drawer}
          campaign={campaign}
          filterRef={filterRef}
          filterValues={filterValues}
        />
      )}
      {currentView === views.matrix && (
        <TalentMatrix
          drawer={drawer}
          campaign={campaign}
          filterRef={filterRef}
          filterValues={filterValues}
        />
      )}
    </Flex>
  );
}

type Modal =
  | null
  | "close-campaign"
  | "campaign-feedback"
  | "manager-preparation-warning"
  | "manage-coordinators-observers"
  | "add-employee-modal-to-campaign";

type ModalsProps = {
  campaign: Campaign;
  activeModal: Modal;
  setActiveModal: Dispatch<SetStateAction<Modal>>;
};
function Modals({ activeModal, campaign, setActiveModal }: ModalsProps) {
  switch (activeModal) {
    case "manager-preparation-warning":
      return (
        <ManagerPreparationWarningModal campaign={campaign} onClose={() => setActiveModal(null)} />
      );

    case "manage-coordinators-observers":
      return (
        <ObserversCoordinatorsModal campaign={campaign} onClose={() => setActiveModal(null)} />
      );

    case "add-employee-modal-to-campaign":
      return (
        <AddEmployeeToCampaignModal campaign={campaign} onClose={() => setActiveModal(null)} />
      );

    case "campaign-feedback":
      return <CampaignFeedbackModal campaign={campaign} onClose={() => setActiveModal(null)} />;

    case "close-campaign":
      return <CloseCampaignModal campaign={campaign} onClose={() => setActiveModal(null)} />;

    default:
      return null;
  }
}
